<template>
  <div class="InfoDetail">
    <div class="InfoDetail-navbar">
      <div class="InfoDetail-navbar-wrap">
        <div class="InfoDetail-navbar-wrap-left" @click="onBackClick">
          <van-icon name="arrow-left" />
        </div>
        <div class="InfoDetail-navbar-wrap-content">全文</div>
      </div>
    </div>
    <div class="InfoDetail-wrap">
      <div class="InfoDetail-actionbar">
        <div class="InfoDetail-actionbar-title">
          {{ dataItem.title}}
        </div>
        <ul class="InfoDetail-actionbar-list">
          <!-- <li class="InfoDetail-actionbar-list-item">
            <img src="~@image/dateico.png" alt="" />2020-05-18
          </li> -->
          <li class="InfoDetail-actionbar-list-item">
            <img src="~@image/cl.png" alt="" />
            {{ dataItem.authority }}
          </li>
          <li class="InfoDetail-actionbar-list-item">
            <img src="~@image/hot.png" alt="" />
            {{ dataItem.hot }}
          </li>

          <li
            @click="alltextinsert(renderHtml)"
              class="InfoDetail-actionbar-list-item"
              style="margin-left:5%"
            >
              <img src="~@image/wenben.png" alt="" />
              全文插入
            </li>

            <!-- <li
              class="InfoDetail-actionbar-list-item txt"
              onselectstart="return false;"
            >
              <img src="~@image/insert.png" alt="" />
              选中插入
            </li> -->

            <li
              class="InfoDetail-actionbar-list-item"
              @click="showPopup"
            >
              <img src="~@image/more.png" alt="" />
              更多
            </li>
        </ul>
      </div>
    </div>
    <div class="InfoDetail-content" v-html="renderHtml" id="text"></div>

    <van-overlay :show="showSingleDownload">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block-header">
            <div class="block-header-title">下载</div>
            <div class="block-header-close" @click="showSingleDownload = false">
              <van-icon name="cross" />
            </div>
          </div>
          <div class="block-content">
            本次将扣减您1下载卷，余额下载券为{{ downloadCount }}卷。
          </div>
          <div class="block-footer">
            <div class="block-footer-btn" @click="handleSingleConfirm">
              确定
            </div>
            <div class="block-footer-btn" @click="showSingleDownload = false">
              取消
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-popup v-model="show" round position="bottom"  :style="{ height: '27%' }">
      <ul class="more">
          <li
          class="more-item"
          @click="handleSingleDown()"
          >
          <img src="~@image/dl.png" alt="" />
          下载
          </li>
        <li
         class="more-item"
          @click="handleShare()"
        >
          <img src="~@image/share.png" alt="" />
          分享
        </li>
        <li
         class="more-item"
          @click="handleTransfer()"
        >
          <img src="~@image/wp.png" alt="" />
          网盘
        </li>

          <li
         class="more-item" v-if="!isCollect"
          @click="handleCollection()"
        >
         <img src="~@image/love.png" alt="" />
          收藏
        </li>

         <li v-else
         class="more-item" @click="batchCollect(dataItem)"
        >
         <img src="~@image/love.png" alt="" />
          取消收藏
        </li>

      </ul>
    </van-popup>

  </div>
</template>

<script>
import {
  openWordDetail,
  getUserCountDownlod,
  getUserCollection,
  saveToCloud,
  batchCollect,
} from "@api/information.js";
import qs from "qs";
export default {
  name: "InfoDetail",
  data() {
    return {
      // 全文文本
      renderHtml: "",
      // 文档id
      id: "",
      // 文档标题
      title: "",
      // 下载券数量
      downloadCount: "",
      // 单个下载的弹窗控制
      showSingleDownload: false,
      //热度
      hot: "",
      // 权威性，
      auth: "",
      //是否滚动页面
      scrolling : false,
      show:false,
      selectionText:'',
      dataItem:"",
      isCollect : false
    };
  },
  created() {
    this.dataItem = JSON.parse(this.$route.query.data);
    
    console.log(this.dataItem)
    this.isCollect = this.dataItem.isMark
    this.getWordContent(this.dataItem.id);
  },
  mounted() {
    // window.addEventListener("scroll", function () {
    //   let scrollHeight = Math.max(document.body.scrollTop());
    //   console.log(scrollHeight);
    // });

    window.selectioninsert = this.selectioninsert
  },
  computed : {
    //iPhone
    isiPhone(){
      return /iPhone|iPad/i.test(navigator.userAgent);
    }
  },
  methods: {
      //选取插入
    selectioninsert:function(){
        // let u = navigator.userAgent;
        // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        // let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        let selObj = window.getSelection().toString();
        return selObj;
    },
    //绑定监听
    listenerHander(){
      document.addEventListener("scroll",this.scrollHandle,false);
    },
    //更多
    showPopup(){
       this.show = true
    },
    //取消收藏
    async batchCollect(data){
       const info = {
         cid:data.id,
       }
       const res = await batchCollect(qs.stringify(info))
       if(res.code === 0){
         this.isCollect = !this.isCollect
           this.$toast("取消收藏！")
           this.show = false
           this.dataItem.isMark = true
       }
    },
    //收藏成功
    async handleCollection(){
      const info = {
        cid:this.dataItem.id,
        text:this.renderHtml,
        title:this.dataItem.title,
      }
      const result = await getUserCollection(qs.stringify(info))
      if(result.code === 0 ){
        this.isCollect = !this.isCollect
        this.$toast("收藏成功！")
        this.show = false
        this.dataItem.isMark = false
      }else{
        this.$toast(result.msg)
        this.show = false
      }
    },
    //全文插入
     alltextinsert(text){
        if(window.android){
         const dict = {
              action: 'post',
              data: text
          };
          var jsonstr = JSON.stringify(dict);
          window.android.postMessage(jsonstr);
        }else if(window.webkit){
          const dict = {
              action: 'post',
              data: text
          };
          var jsonstr = JSON.stringify(dict);
          window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonstr)
        }
    },
    //滚动监听处理
    scrollHandle(){
      let top = document.body.scrollTop || document.documentElement.scrollTop;
      top > 0 ? this.scrolling = true : this.scrolling = false;
    },
    /*//绑定监听
    listenerHander(){
      document.addEventListener("scroll",this.scrollHandle,false);
    },
    //滚动监听处理
    scrollHandle(){
      let top = document.body.scrollTop || document.documentElement.scrollTop;
      top > 0 ? this.scrolling = true : this.scrolling = false;
    },*/
    // 单个下载
    handleSingleDown() {
      this.showSingleDownload = true;
      this.show = false
      this.getUserCountDownlod();
    },
    // 确定下载
    handleSingleConfirm() {
      const params = {
        action: "writerMergeWord",
        data: {
          type: 1,
          ids: this.dataItem.id,
          filename: this.dataItem.title,
        },
      };
      if (window.android) {
        window.android.postMessage(JSON.stringify(params));
      } else if (window.webkit) {
        window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(params)
        );
      } else {
        // 隐藏弹出框
        this.showSingleDownload = false;
      }
      // 隐藏弹出框
      this.showSingleDownload = false;
    },
    //  分享单个文件
    handleShare() {
      this.show = false
      const params = {
        action: "share",
        data: {
          type: 1,
          ids: this.dataItem.id,
          filename: this.dataItem.title,
        },
      };
      if (window.android) {
        window.android.postMessage(JSON.stringify(params));
      } else if (window.webkit) {
        // ios
        window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(params)
        );
      }

      console.log("handleShare");
    },
    // 获取全文
    async getWordContent(id) {
      this.$toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });
      const requestOptions = {
        type: 1,
        id,
      };
      const result = await openWordDetail(qs.stringify(requestOptions));

      if (result.code === 0) {
        this.renderHtml = result.msg;
        this.$toast.clear();
      } else {
        this.$toast({
          message: result.msg,
        });
      }
    },
    // 返回上一个历史记录
    onBackClick() {
      this.$router.go(-1);
    },
    async getUserCountDownlod() {
      const result = await getUserCountDownlod(qs.stringify({ type: 3 }));
      this.downloadCount = result.data;
    },
    // 转存云文档
    async handleTransfer() {
      const requestParam = {
        md5: this.dataItem.id,
      };
      const result = await saveToCloud(qs.stringify(requestParam));
      if (result.code === 0) {
        this.$toast("已转存至云文档");
        this.show = false
      } else {
        this.$toast({
          message: result.msg,
        });
         this.show = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.InfoDetail {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: PingFang SC;
  &-navbar {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;
    z-index: 99999;
    // top: 0px;
    width: 100%;
    height: 40px;
    box-shadow: 0px 6px 13px 0px rgba(196, 196, 196, 0.2);
    &-wrap {
      position:fixed;
      width: 100%;
      height:40px;
      display: flex;
      align-items: center;
      background-color: #fff;
      &-left {
        position: absolute;
        display: flex;
        align-items: center;
        left: 15px;
        top: 0;
        height: 100%;
        font-size: 15px;
        font-weight: 500;
        color: #333333;
      }
      &-content {
        font-size: 17px;
        width: 100%;
        text-align: center;
        font-weight: bold;
      }
    }
  }
  &-wrap {
    padding: 10px 15px 0px;
    .InfoDetail-actionbar {
      padding-bottom: 15px;
      border-bottom: 1px solid #e3e3e3;
      &-title {
        font-size: 17px;
        font-weight: bold;
        color: #333333;
      }
      &-list {
        display: flex;
        // justify-content: space-between;
        margin-top: 15px;
        &-item {
          display: flex;
          align-items: center;
          font-size: 11px;
          cursor: pointer;
          font-weight: 500;
          color: #666666;
          margin-right:15px;
          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 345px;
      border-radius: 8px;
      padding: 0 21px;
      background-color: #fff;
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 54px;
        border-bottom: 1px solid #efefef;
        &-title {
          font-size: 15px;
        }
      }
      &-content {
        padding: 15px 0;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      &-footer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
        &-btn {
          height: 40px;
          padding: 0 22px;
          line-height: 40px;
          border-radius: 8px;
          color: #ffffff;
          background-color: #4588ff;
        }
        &-btn + div {
          margin-left: 10px;
          color: #666666;
          background-color: #f1f1f1;
        }
      }
    }
  }
 .more{
     margin-left: 20px;
     display: flex;
     flex-direction: column;
     height: 100%;
     .more-item{
       padding-top: 24px;
       font-size: 14px;
       font-family: PingFang SC;
       font-weight: 500;
       color: #333333;
       flex: 1;
       display: flex;
      img{
        width: 20px;
        height: 20px;
        margin-right: 15px;
      }
     }
  }
}
</style>

<style>
  .InfoDetail-content>div:nth-of-type(2){
     width: 306px !important;
     margin-bottom: 36px !important;
     margin-top: 36px !important;
     margin-left: 36px !important;
     margin-right: 36px !important;
  } 
</style>
